import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { QuestionCategory } from './components/QuestionCategory';
import { CreateWorksheets } from './components/CreateWorksheets';
import { SelectWorksheet } from './components/SelectWorksheet';
import { CompletedQuizList } from './components/CompletedQuizList';
import { Exam } from "./components/Exam";
import { ExamReport } from "./components/ExamReport";
import { ExamReview } from "./components/ExamReview";
import { AuthRedirect } from "./components/AuthRedirect";
import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route exact path='/questioncategory' component={QuestionCategory} />
                <Route exact path='/createworksheets' component={CreateWorksheets} />
                <Route path='/exam/:worksheetid/:examid?' component={Exam} />
                <Route path='/examreport/:examid' component={ExamReport} />
                <Route path='/examreview/:examid/:overridereviewstate?' component={ExamReview} />
                <Route exact path='/selectworksheet' component={SelectWorksheet} />
                <Route exact path='/completedquizlist' component={CompletedQuizList} />
            </Layout>
        );
    }
}

import React, { Component } from 'react';
import ExamQuestionItem from "./ExamQuestionItem";
import "./Exam.css"

export class ExamReview extends Component {
    static displayName = ExamReview.name;

    constructor(props) {
        super(props);
        this.state = { examReviewModel: null, loading: true, submitStatus: null };
        const { examid, overridereviewstate } = this.props.match.params;

        this.handleSubmit = this.handleSubmit.bind(this);

        let url = 'api/Exam/GetExamReview/' + examid;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (overridereviewstate !== null && overridereviewstate) {
                    data.reviewed = true;
                }
                this.setState({ examReviewModel: data, loading: false });
            });
    }

    handleSubmit(e) {
        this.setState({ submitStatus: "Saving exam review..." });
        fetch('api/Exam/SaveExamReview',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state.examReviewModel)
            })
            .then(response => {
                this.setState({ submitStatus: "Exam review is saved successfully." });
            });
        e.preventDefault();
    }

    renderExamWorksheet(data) {
        let submitButton = "";
        if (!data.examReviewModel.reviewed) {
            submitButton = <div className="form-group">
                <button className="btn btn-primary btn-wrap-text" onClick={this.handleSubmit} >Submit</button>
            </div >;
        }

        let renderedExamQuestions = data.examReviewModel.examReviewQuestions.map((q, i) =>
            <div key={q.questionId}>
                <label><b>Question {i + 1}.</b></label>
                <ExamQuestionItem examQuestion={q} examId={data.examReviewModel.examId} disableAnswerEdit={true} reviewed={data.examReviewModel.reviewed} />
            </div>
        );

        return (<div>
            {submitButton}
            {this.state.submitStatus}
            {renderedExamQuestions}
        </div>);
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderExamWorksheet(this.state);

        return (
            <form>
                {contents}
            </form>
        );
    }
}
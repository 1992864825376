import React, { Component } from 'react';
import Pagination from 'react-bootstrap/Pagination'
import QuestionCategoryItem from "./QuestionCategoryItem"
import "./QuestionCategory.css";

export class QuestionCategory extends Component {
    static displayName = QuestionCategory.name;

    constructor(props) {
        super(props);
        this.state = { printmode: false, paginationSize: 100, currentPage: -1, topics: null, selectedTopic: -1, loading: true, status: null, topicQuestions: null, questionListLoading: false, questionListStatus: null, submitStatus: null };

        this.handleTopicChange = this.handleTopicChange.bind(this);
        this.handleTopicChangeLoad = this.handleTopicChangeLoad.bind(this);
        this.handleSubmitPreferences = this.handleSubmitPreferences.bind(this);
        this.handlepageChanged = this.handlePageChanged.bind(this);
        this.renderPaginatedQuestions = this.renderPaginatedQuestions.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handlePrintMode = this.handlePrintMode.bind(this);

        fetch('api/QuestionCategory/GetTopics')
            .then(response => response.json())
            .then(data => {
                this.setState({ topics: data, loading: false });
            });
    }

    handlePrintMode(event) {
        this.setState({ printmode: true });
    }

    handlePageSizeChange(event) {
        const value = event.target.value;
        if (value < 100) {
            this.setState({ paginationSize: value });
        }

        event.preventDefault();
    }

    handleTopicChange(event) {
        if (event.target.selectedIndex > 0) {
            this.setState({ selectedTopic: event.target.selectedIndex - 1 });
        } else {
            this.setState({ selectedTopic: -1 });
        }
        event.preventDefault();
    }

    handleTopicChangeLoad(event) {
        this.setState({ questionListLoading: true, questionListStatus: "Loading topic questions...", submitStatus: null });

        let index = this.state.selectedTopic;

        if (index < 0)
            return;

        let selectedTopic = this.state.topics[index];

        let url = 'api/QuestionCategory/GetTopicQuestions/' + btoa(selectedTopic);
        fetch(url)
            .then(response => response.json())
            .then(data => {
                this.setState({ topicQuestions: data, currentPage: 1, questionListLoading: false, questionListStatus: null });
            });

        event.preventDefault();
    }

    handleSubmitPreferences(event) {
        this.setState({ submitStatus: "Saving Category preferences..." });
        fetch('api/QuestionCategory/SaveQuestionCategories',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state.topicQuestions)
            })
            .then(response => {
                this.setState({ submitStatus: "Category preferences saved successfully." });
            });

        event.preventDefault();
    }

    handlePageChanged(e) {
        this.setState({ currentPage: parseInt(e.target.text), submitStatus: null });
        console.log(e.target.text);
    }

    handleFormSubmit(e) {
        e.preventDefault();
        e.target.reset();
    }

    getPageCount(array, pageSize) {
        const pages = array.length / pageSize;
        return Math.ceil(pages);
    }

    paginate(array, pageSize, pageNumber) {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }

    renderPaginatedQuestions(data, pageNumber) {
        var pageQuestions = this.paginate(data.topicQuestions, data.paginationSize, pageNumber);
        var seqStart = (pageNumber - 1) * data.paginationSize;
        let renderedPageQuestions = pageQuestions.map((q, i) =>
            <div key={q.questionId}>
                <label><b>Question {seqStart + i + 1}.</b></label>
                <QuestionCategoryItem question={q} printmode={this.state.printmode} />
            </div >
        );

        let submitButton = this.state.printmode ? "" : <div className="form-group">
            <button className="btn btn-primary btn-wrap-text" onClick={this.handleSubmitPreferences.bind(this)} >Submit Category Preferences</button>

        </div >;

        let submitStatus = "";
        if (data.submitStatus !== null) {
            submitStatus = <div className="alert alert-success" role="alert"> {data.submitStatus} </div>
        }


        const totalPages = this.getPageCount(data.topicQuestions, data.paginationSize);
        let active = pageNumber;
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item key={number} active={number === active}>
                    {number}
                </Pagination.Item>,
            );
        }

        const pagination = this.state.printmode ? "" : <Pagination onClick={this.handlePageChanged.bind(this)}>
            {items}
        </Pagination>;

        return (<div>
            {submitButton}
            {submitStatus}
            {renderedPageQuestions}
            {pagination}
        </div>);
    }

    renderQuestionList(data) {
        const renderStatus = data.questionListStatus === null ? ""
            : <div className="alert alert-success" role="alert"> {data.questionListStatus} </div>;

        let contents = "";
        if (data.questionListLoading === false && data.topicQuestions !== null) {
            contents = this.renderPaginatedQuestions(data, data.currentPage);
        }

        return (
            <div>
                {renderStatus}
                {contents}
            </div>
        );
    }

    renderTopics(data) {
        const optionItems = data.topics.map((topic, i) =>
            <option value={i} key={i}>
                {topic}
            </option >);

        const renderStatus = data.status === null ? ""
            : <div className="alert alert-success" role="alert"> {data.status} </div>;

        const nonPrintModePage = <div className="form-group">
            <label>All topics:</label>
            <div />
            <select className="custom-select" id="topics" value={this.state.selectedTopic} onChange={this.handleTopicChange}>
                <option value="Choose one..">Choose one..</option>
                {optionItems}
            </select>
            <div className="form-group">
                <label htmlFor="pageSize">Page size</label>
                <input type="text" className="form-control" value={this.state.paginationSize} onChange={this.handlePageSizeChange} id="pageSize" />
            </div>
            <div className="form-group">
                <input className="btn btn-primary btn-wrap-text" type="button" value="Load" onClick={this.handleTopicChangeLoad} />
            </div>
            <div className="form-group">
                <input className="btn btn-primary btn-wrap-text" type="button" value="PrintMode" onClick={this.handlePrintMode} />
            </div>
            {this.renderQuestionList(this.state)}
        </div>;

        const printModePage = <div className="form-group">{this.renderQuestionList(this.state)}</div>;

        const printModePageRender = this.state.printmode ? printModePage : nonPrintModePage;

        return (
            <div>
                {renderStatus}
                <div className="form-row">
                    {printModePageRender}
                </div>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderTopics(this.state);

        return (
            <form>
                {contents}
            </form>
        );
    }
}
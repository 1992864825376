import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

class QuestionCategoryItem extends Component {
    static displayName = QuestionCategoryItem.name;

    constructor(props) {
        super(props);
        this.state = { printmode: props.printmode, question: props.question, favoriteQuestion: props.question.favoriteQuestion, questionForDailyPractice: props.question.questionForDailyPractice };

        this.handleFavoriteQuestion = this.handleFavoriteQuestion.bind(this);
        this.handleDailyPracticeQuestion = this.handleDailyPracticeQuestion.bind(this);
    }

    handleFavoriteQuestion(event) {
        const q = this.state.question;
        q.favoriteQuestion = event.target.checked;
        this.setState(state => ({ favoriteQuestion: !state.favoriteQuestion }));
    }

    handleDailyPracticeQuestion(event) {
        const q = this.state.question;
        q.questionForDailyPractice = event.target.checked;
        this.setState(state => ({ questionForDailyPractice: !state.questionForDailyPractice }));
    }

    render() {
        var favoriteId = "favorite" + this.state.question.questionId;
        var dailyPracticeId = "dailyPractice" + this.state.question.questionId;

        const printmodeRender = <img src={this.state.question.questionImageUrl} />;

        let imageTabs = this.state.printmode? printmodeRender: <Tabs defaultActiveKey="question" id="question-answer-tab">
            <Tab eventKey="question" title="Question">
                <img src={this.state.question.questionImageUrl} />
            </Tab>
            <Tab eventKey="answer" title="Answer">
                <img src={this.state.question.answerImageUrl} />
            </Tab>;
        </Tabs>;

        const nonPrintModeButtons = this.state.printmode ? "" : <div>
            <div className="form-check question-box-favorite">
                <input className="form-check-input" type="checkbox" id={favoriteId} onChange={this.handleFavoriteQuestion} checked={this.state.favoriteQuestion === true} />
                <label className="form-check-label" htmlFor={favoriteId} >
                    FavoriteQuestion
            </label>
            </div>
            <div className="form-check question-box-dailypractice">
                <input className="form-check-input" type="checkbox" id={dailyPracticeId} onChange={this.handleDailyPracticeQuestion} checked={this.state.questionForDailyPractice === true} />
                <label className="form-check-label" htmlFor={dailyPracticeId}>
                    DailyPracticeQuestion
            </label>
            </div>
        </div>

        return (
            <div className="form-group question-box">
                {imageTabs}
                {nonPrintModeButtons}
            </div >
        );
    }
}

export default QuestionCategoryItem;
import React, { Component } from "react";
import { Route, Redirect } from 'react-router-dom';

export class AuthRedirect extends Component {
    constructor(props) {
        super();
        this.state = { ...props };
    }

    render() {
        return (<Redirect to="/.auth/customAuth/Login" />);
    }
}

export default AuthRedirect;
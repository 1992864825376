import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

export class SelectWorksheet extends Component {
    static displayName = SelectWorksheet.name;

    constructor(props) {
        super(props);
        this.state = {
            availableWorksheets: null,
            loading: true,
            selectedSingleTopic: -1,
            selectedSingleTopicWorksheet: -1,
            selectedGroupTopic: -1,
            selectedGroupTopicWorksheet: -1,
            singleTopicWorksheets: null,
            groupTopicWorksheets: null,
            selectedWorksheetId: null
        };

        this.handleRunTest = this.handleRunTest.bind(this);
        this.handleSingleTopicChange = this.handleSingleTopicChange.bind(this);
        this.handleSingleTopicWorksheetChange = this.handleSingleTopicWorksheetChange.bind(this);
        this.handleGroupTopicChange = this.handleGroupTopicChange.bind(this);
        this.handleGroupTopicWorksheetChange = this.handleGroupTopicWorksheetChange.bind(this);

        fetch('api/Worksheet/GetAvailableWorksheets')
            .then(response => response.json())
            .then(data => {
                this.setState({ availableWorksheets: data, loading: false });
            });
    }

    handleRunTest(e) {
        if (this.state.selectedWorksheetId > 0) {
            this.props.history.push("/exam/" + this.state.selectedWorksheetId);
        } else {
            alert("Please select a worksheet for a topic.");
        }

        e.preventDefault();
    }

    handleSingleTopicChange(e) {
        this.state.selectedWorksheetId = -1;
        let selectedIndex = e.target.selectedIndex;
        if (selectedIndex > 0) {
            let selectedTopic = this.state.availableWorksheets.singleTopics[selectedIndex - 1];

            let worksheets = [];
            this.state.availableWorksheets.singleTopicWorksheets.forEach(e => {
                if (e.topic === selectedTopic) {
                    worksheets.push(e);
                }
            });

            this.setState({ selectedSingleTopic: selectedIndex - 1, singleTopicWorksheets: worksheets });
        }

        e.preventDefault();
    }

    handleSingleTopicWorksheetChange(e) {
        let selectedIndex = e.target.selectedIndex;
        if (selectedIndex > 0) {
            let selectedWorksheet = this.state.singleTopicWorksheets[selectedIndex - 1];

            this.setState({ selectedSingleTopicWorksheet: selectedIndex - 1, selectedWorksheetId: selectedWorksheet.id });
        }

        e.preventDefault();
    }

    handleGroupTopicChange(e) {
        this.state.selectedWorksheetId = -1;
        let selectedIndex = e.target.selectedIndex;
        if (selectedIndex > 0) {
            let selectedTopic = this.state.availableWorksheets.groupTopics[selectedIndex - 1];

            let worksheets = [];
            this.state.availableWorksheets.groupTopicWorksheets.forEach(e => {
                if (e.topic === selectedTopic) {
                    worksheets.push(e);
                }
            });

            this.setState({ selectedGroupTopic: selectedIndex - 1, groupTopicWorksheets: worksheets });
        }
        e.preventDefault();
    }

    handleGroupTopicWorksheetChange(e) {
        let selectedIndex = e.target.selectedIndex;
        if (selectedIndex > 0) {
            let selectedWorksheet = this.state.groupTopicWorksheets[selectedIndex - 1];

            this.state.selectedWorksheetId = selectedWorksheet.id;
        }

        e.preventDefault();
    }

    renderAvailableWorksheets() {
        let singleTopics = this.state.availableWorksheets.singleTopics.map((topic, i) =>
            <option value={i} key={i}>
                {topic}
            </option>
        );

        let singleTopicWorksheets = this.state.singleTopicWorksheets === null ? "" :
            this.state.singleTopicWorksheets.map((worksheet, i) =>
                <option value={i} key={i}>
                    {"Worksheet " + worksheet.id}
                </option>
            );

        let groupTopics = this.state.availableWorksheets.groupTopics.map((topic, i) =>
            <option value={i} key={i}>
                {topic.name}
            </option>
        );

        let groupTopicWorksheets = this.state.groupTopicWorksheets === null ? "" :
            this.state.groupTopicWorksheets.map((worksheet, i) =>
                <option value={i} key={i}>
                    {"Worksheet " + worksheet.id}
                </option>
            );


        let tabs = <Tabs defaultActiveKey="singleTopic" id="worksheet-selector-tab">
            <Tab eventKey="singleTopic" title="Single Topics">
                <select className="custom-select" id="singleTopics" value={this.state.selectedSingleTopic} onChange={this.handleSingleTopicChange}>
                    <option value="Choose one..">Choose one..</option>
                    {singleTopics}
                </select>
                <div>
                    <b>Choose worksheet for the above topic:</b>
                    <select className="custom-select" id="singleTopicWorksheets" value={this.state.selectedSingleTopicWorksheet} onChange={this.handleSingleTopicWorksheetChange}>
                        <option value="Choose one..">Choose one..</option>
                        {singleTopicWorksheets}
                    </select>
                </div>
            </Tab>
            <Tab eventKey="groupTopic" title="Group Topics">
                <select className="custom-select" id="groupTopics" value={this.state.selectedGroupTopic} onChange={this.handleGroupTopicChange}>
                    <option value="Choose one..">Choose one..</option>
                    {groupTopics}
                </select>
                <div>
                    <b>Choose worksheet for the above topic:</b>
                    <select className="custom-select" id="groupTopicWorksheets" value={this.state.selectedGroupTopicWorksheet} onChange={this.handleGroupTopicWorksheetChange}>
                        <option value="Choose one..">Choose one..</option>
                        {groupTopicWorksheets}
                    </select>
                </div>
            </Tab>
        </Tabs>;

        let runTestButton = <div className="form-group">
            <button className="btn btn-primary btn-wrap-text" onClick={this.handleRunTest} >Run Test</button>
        </div>;

        return (<div>
            {tabs}
            {runTestButton}
        </div>);
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderAvailableWorksheets(this.state);

        return (
            <form>
                {contents}
            </form>
        );
    }
}
import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

class ExamQuestionItem extends Component {
    static displayName = ExamQuestionItem.name;

    constructor(props) {
        super(props);
        this.state = {
            examId: props.examId,
            examQuestion: props.examQuestion,
            disableAnswerEdit: props.disableAnswerEdit,
            reviewed: props.reviewed,
            markedCorrect: props.examQuestion.markedCorrect
        };

        this.handleAnswerChange = this.handleAnswerChange.bind(this);
        this.handleAnswerBlur = this.handleAnswerBlur.bind(this);
        this.handleMarkItCorrect = this.handleMarkItCorrect.bind(this);
    }

    handleAnswerChange(e) {
        let q = this.state.examQuestion;
        q.answerText = e.target.value;
        this.setState({ examQuestion: q });
        e.preventDefault();
    }

    handleAnswerBlur(e) {
        if (!this.state.disableAnswerEdit) {
            if (e.target.value !== null && e.target.value !== "") {
                let question = this.state.examQuestion;
                question.answerText = e.target.value;
                fetch('api/exam/SaveExamAnswers',
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ examId: this.state.examId, examQuestions: [this.state.examQuestion] })
                    });
            }
            e.preventDefault();
        }
    }

    handleMarkItCorrect(e) {
        let eq = this.state.examQuestion;
        eq.markedCorrect = e.target.checked;
        this.setState(state => ({ markedCorrect: !state.markedCorrect }));
        e.preventDefault();
    }


    render() {
        let answerId = "answer" + this.state.examQuestion.questionId;
        let textArea = !this.state.disableAnswerEdit ?
            <input type="text" className="form-control" id={answerId} value={this.state.examQuestion.answerText} onChange={this.handleAnswerChange} onBlur={this.handleAnswerBlur} />
            : <input type="text" className="form-control" id={answerId} value={this.state.examQuestion.answerText} disabled />
        let correctAnswer = "";
        if (this.state.disableAnswerEdit) {
            let markCorrectId = "markcorrect" + this.state.examQuestion.questionId;
            if (this.state.reviewed || this.state.examQuestion.examResultId < 0) {
                // Check box in disable state
                correctAnswer = <div className="form-check">
                    <input className="form-check-input" type="checkbox" id={markCorrectId} onChange={this.handleMarkItCorrect} checked={this.state.examQuestion.markedCorrect === true} disabled />
                    <label className="form-check-label" htmlFor={markCorrectId}>
                        Mark if correct
                    </label>
                </div>
            } else {
                // Check box in enable state
                correctAnswer = <div className="form-check">
                    <input className="form-check-input" type="checkbox" id={markCorrectId} onChange={this.handleMarkItCorrect} checked={this.state.examQuestion.markedCorrect === true} />
                    <label className="form-check-label" htmlFor={markCorrectId}>
                        Mark if correct
                    </label>
                </div>
            }
        }

        let answerTab = this.state.disableAnswerEdit || this.state.reviewed ?
            <Tab eventKey="answer" title="Answer">
                <img src={this.state.examQuestion.answerUrl} />
            </Tab> : "";

        let imageTabs = <Tabs defaultActiveKey="question" id="question-answer-tab">
            <Tab eventKey="question" title="Question">
                <img src={this.state.examQuestion.questionImageUrl} />
            </Tab>
            {answerTab}
        </Tabs >;

        return (
            <div className="form-group question-box">
                {imageTabs}
                <div className="form-group">
                    <label className="question-box-answer" htmlFor={answerId} ><b>Enter your answer:</b> </label>
                    {textArea}
                </div>
                {correctAnswer}
            </div >
        );
    }
}

export default ExamQuestionItem;
import React, { Component } from 'react';
import ExamQuestionItem from "./ExamQuestionItem";
import Timer from 'react-compound-timer';
import "./Exam.css"

export class Exam extends Component {
    static displayName = Exam.name;

    constructor(props) {
        super(props);
        this.state = { examModel: null, loading: true, submitStatus: null };
        const { worksheetid, examid } = this.props.match.params;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleExamTimeUp = this.handleExamTimeUp.bind(this);

        if (examid === undefined || examid === null) {
            let url = 'api/Exam/CreateExamForWorksheet/' + worksheetid;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    this.setState({ examModel: data, loading: false });
                });
        } else {
            let url = 'api/Exam/GetExam/' + examid + '/false';
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    this.setState({ examModel: data, loading: false });
                });
        }

        this.handleWindowBeforeUnload = this.handleWindowBeforeUnload.bind(this);
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
    }

    handleWindowBeforeUnload(e) {
        alert('Hello!');
        e.event.returnValue = 'unloading';
        return "unloading";
    }

    handleSubmit(e) {
        this.submitAnswers();
        e.preventDefault();
    }

    submitAnswers() {
        this.setState({ submitStatus: "Saving exam review..." });
        fetch('api/exam/SaveExamAnswers',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state.examModel)
            })
            .then(response => {
                this.setState({ submitStatus: "Exam review is saved successfully." });
            });

        // Redirect to review page
        this.props.history.push("/examreview/" + this.state.examModel.examId + "/true");
    }

    handleExamTimeUp(e) {
        alert("Sorry, time is up!!!");

        this.submitAnswers();

        e.preventDefault();
    }

    renderExamWorksheet(data) {
        let submitButton = <div className="form-group">
            <button className="btn btn-primary btn-wrap-text" onClick={this.handleSubmit} >Submit</button>
        </div >;

        let renderedExamQuestions = data.examModel.examQuestions.map((q, i) =>
            <div key={q.questionId}>
                <label><b>Question {i + 1}.</b></label>
                <ExamQuestionItem examQuestion={q} examId={data.examModel.examId} />
            </div>
        );

        let timer = <Timer
            initialTime={data.examModel.examTimeInMilliSeconds + 2}
            direction="backward"
            checkpoints={[
                {
                    time: 2,
                    callback: this.handleExamTimeUp,
                }
            ]}>
            {() => (
                <React.Fragment>
                    <b>
                        <Timer.Hours /><span> HH:  </span>
                        <Timer.Minutes /><span> mm:  </span>
                        <Timer.Seconds /><span> ss  </span>
                    </b>
                </React.Fragment>)}
        </Timer>;

        return (<div>
            {submitButton}
            {this.submitStatus}
            {timer}
            {renderedExamQuestions}
        </div>);
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderExamWorksheet(this.state);

        return (
            <form>
                {contents}
            </form>
        );
    }
}
import React, { Component } from 'react';

export class ExamReport extends Component {
    static displayName = ExamReport.name;

    constructor(props) {
        super(props);
        this.state = { topics: null, selectedTopic: -1, loading: true, status: null, topicQuestions: null, questionListLoading: false, questionListStatus: null };
    }

    render() {

        return (
            <form>
                <div>Exam Report page </div>
            </form>
        );
    }
}
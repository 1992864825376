import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CreateGroupTopic from './CreateGroupTopic';

export class CreateWorksheets extends Component {
    static displayName = CreateWorksheets.name;

    constructor(props) {
        super(props);
        this.state = {
            availableWorksheets: null,
            loading: true,
            selectedSingleTopic: -1,
            selectedSingleTopicWorksheet: -1,
            selectedGroupTopic: -1,
            selectedGroupTopicId: -1,
            selectedGroupTopicWorksheet: -1,
            singleTopicWorksheets: null,
            groupTopicWorksheets: null,
            selectedWorksheetId: null,
            includeSingleTopicFavoriteQs: false,
            includeSingleTopicDailyPracticeQs: false,
            includeGroupTopicFavoriteQs: false,
            includeGroupTopicDailyPracticeQs: false,
            NoOfQuestionsInEachWorksheet: 20,
            TimeToCompleteWorksheetInMinutes: 20
        };

        this.handleSyncQuestions = this.handleSyncQuestions.bind(this);

        this.handleSingleTopicCreateWorksheets = this.handleSingleTopicCreateWorksheets.bind(this);
        this.handleGroupTopicCreateWorksheets = this.handleGroupTopicCreateWorksheets.bind(this);

        this.handleSingleTopicChange = this.handleSingleTopicChange.bind(this);
        this.handleSingleTopicWorksheetChange = this.handleSingleTopicWorksheetChange.bind(this);
        this.handleSingleTopicFavoriteQsChange = this.handleSingleTopicFavoriteQsChange.bind(this);
        this.handleSingleTopicDailyPracticeQsChange = this.handleSingleTopicDailyPracticeQsChange.bind(this);
        this.handleSingleTopicDeleteWorksheets = this.handleSingleTopicDeleteWorksheets.bind(this);

        this.handleGroupTopicChange = this.handleGroupTopicChange.bind(this);
        this.handleGroupTopicWorksheetChange = this.handleGroupTopicWorksheetChange.bind(this);
        this.handleGroupTopicFavoriteQsChange = this.handleGroupTopicFavoriteQsChange.bind(this);
        this.handleGroupTopicDailyPracticeQsChange = this.handleGroupTopicDailyPracticeQsChange.bind(this);
        this.handleDeleteGroupTopic = this.handleDeleteGroupTopic.bind(this);

        this.handleNoOfQuestionsInEachWorksheet = this.handleNoOfQuestionsInEachWorksheet.bind(this);
        this.handleTimeToCompleteWorksheet = this.handleTimeToCompleteWorksheet.bind(this);

        this.retrieveAvailableWorksheets = this.retrieveAvailableWorksheets.bind(this);
        this.handleGroupCreated = this.handleGroupCreated.bind(this);

        this.generateWorkSheets = this.generateWorkSheets.bind(this);

        this.retrieveAvailableWorksheets();
    }

    retrieveAvailableWorksheets() {
        fetch('api/Worksheet/GetAvailableWorksheets')
            .then(response => response.json())
            .then(data => {
                this.setState({ availableWorksheets: data, loading: false });
            });
    }

    handleDeleteGroupTopic(e) {
        fetch('api/Worksheet/DeleteGroupTopic/' + this.state.selectedGroupTopicId, {
            method: 'DELETE'
        })
            .then(response => {
                this.setState({ submitStatus: "Deleted group worksheets successfully." });
                this.retrieveAvailableWorksheets();
            });

        e.preventDefault();
    }

    handleGroupCreated(e) {
        this.retrieveAvailableWorksheets();
    }

    handleSyncQuestions(e) {
        fetch('api/Worksheet/SyncDb')
            .then(response => {
                this.setState({ submitStatus: "Sync questions is completed successfully." });
                this.retrieveAvailableWorksheets();
            });
        e.preventDefault();
    }

    handleSingleTopicCreateWorksheets(e) {
        let postData = {
            topic: this.state.availableWorksheets.singleTopics[this.state.selectedSingleTopic],
            includeFavoriteQs: this.state.includeSingleTopicFavoriteQs,
            includeDailyPracticeQs: this.state.includeSingleTopicDailyPracticeQs,
            noOfQuestions: this.state.NoOfQuestionsInEachWorksheet,
            timeToCompleteInMinutes: this.state.TimeToCompleteWorksheetInMinutes
        };

        this.generateWorkSheets(postData);
        e.preventDefault();
    }

    handleSingleTopicDeleteWorksheets(e) {
        fetch('api/Worksheet/SingleTopicWorksheets/' + btoa(this.state.availableWorksheets.singleTopics[this.state.selectedSingleTopic]), {
            method: 'DELETE'
        })
            .then(response => {
                this.setState({ submitStatus: "Single topic worksheets are deleted successfully." });
            });

        e.preventDefault();

        e.preventDefault();
    }

    handleGroupTopicCreateWorksheets(e) {
        let postData = {
            groupId: this.state.selectedGroupTopicId,
            includeFavoriteQs: this.state.includeGroupTopicFavoriteQs,
            includeDailyPracticeQs: this.state.includeGroupTopicDailyPracticeQs,
            noOfQuestions: this.state.NoOfQuestionsInEachWorksheet,
            timeToCompleteInMinutes: this.state.TimeToCompleteWorksheetInMinutes
        };

        this.generateWorkSheets(postData);
        e.preventDefault();
    }

    generateWorkSheets(postData) {
        fetch('api/worksheet/CreateWorksheet',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            })
            .then(response => {
                this.setState({ submitStatus: "Worksheets are created successfully." });
                this.retrieveAvailableWorksheets();
            });
    }

    handleSingleTopicChange(e) {
        this.state.selectedWorksheetId = -1;
        let selectedIndex = e.target.selectedIndex;
        if (selectedIndex > 0) {
            let selectedTopic = this.state.availableWorksheets.singleTopics[selectedIndex - 1];

            let worksheets = [];
            this.state.availableWorksheets.singleTopicWorksheets.forEach(e => {
                if (e.topic === selectedTopic) {
                    worksheets.push(e);
                }
            });

            this.setState({ selectedSingleTopic: selectedIndex - 1, singleTopicWorksheets: worksheets });
        }

        e.preventDefault();
    }

    handleSingleTopicWorksheetChange(e) {
        let selectedIndex = e.target.selectedIndex;
        if (selectedIndex > 0) {
            let selectedWorksheet = this.state.singleTopicWorksheets[selectedIndex - 1];

            this.setState({ selectedSingleTopicWorksheet: selectedIndex - 1, selectedWorksheetId: selectedWorksheet.id });
        }

        e.preventDefault();
    }

    handleGroupTopicChange(e) {
        this.state.selectedWorksheetId = -1;
        let selectedIndex = e.target.selectedIndex;
        if (selectedIndex > 0) {
            let selectedTopic = this.state.availableWorksheets.groupTopics[selectedIndex - 1];
            let selGroupTopicId = selectedTopic.id;

            let worksheets = [];
            this.state.availableWorksheets.groupTopicWorksheets.forEach(e => {
                if (e.topic === selectedTopic.name) {
                    worksheets.push(e);
                }
            });

            this.setState({
                selectedGroupTopic: selectedIndex - 1,
                selectedGroupTopicId: selGroupTopicId,
                groupTopicWorksheets: worksheets
            });
        }
        e.preventDefault();
    }

    handleGroupTopicWorksheetChange(e) {
        let selectedIndex = e.target.selectedIndex;
        if (selectedIndex > 0) {
            let selectedWorksheet = this.state.groupTopicWorksheets[selectedIndex - 1];

            this.state.selectedWorksheetId = selectedWorksheet.id;
        }

        e.preventDefault();
    }

    handleSingleTopicFavoriteQsChange(e) {
        //let favQs = e.target.checked;
        this.setState(state => ({ includeSingleTopicFavoriteQs: !state.includeSingleTopicFavoriteQs }));
        //this.setState(state => ({ includeSingleTopicFavoriteQs: favQs }));
        e.preventDefault();
    }

    handleSingleTopicDailyPracticeQsChange(e) {
        //let dailyQs = e.target.checked;
        this.setState(state => ({ includeSingleTopicDailyPracticeQs: !state.includeSingleTopicDailyPracticeQs }));
        //this.setState(state => ({ includeSingleTopicDailyPracticeQs: dailyQs }));
        e.preventDefault();
    }

    handleGroupTopicFavoriteQsChange(e) {
        let favQs = e.target.checked;
        //this.setState(state => ({ includeSingleTopicFavoriteQs: !state.includeSingleTopicFavoriteQs }));
        this.setState(state => ({ includeGroupTopicFavoriteQs: favQs }));
        e.preventDefault();
    }

    handleGroupTopicDailyPracticeQsChange(e) {
        let dailyQs = e.target.checked;
        //this.setState(state => ({ includeSingleTopicFavoriteQs: !state.includeSingleTopicFavoriteQs }));
        this.setState(state => ({ includeGroupTopicDailyPracticeQs: dailyQs }));
        e.preventDefault();
    }

    handleNoOfQuestionsInEachWorksheet(e) {

        this.setState({ NoOfQuestionsInEachWorksheet: e.target.value });
        e.preventDefault();
    }

    handleTimeToCompleteWorksheet(e) {

        this.setState({ TimeToCompleteWorksheetInMinutes: e.target.value });
        e.preventDefault();
    }

    renderAvailableWorksheets() {
        let singleTopics = this.state.availableWorksheets.singleTopics.map((topic, i) =>
            <option value={i} key={i}>
                {topic}
            </option>
        );

        let singleTopicWorksheets = this.state.singleTopicWorksheets === null ? "" :
            this.state.singleTopicWorksheets.map((worksheet, i) =>
                <option value={i} key={i}>
                    {"Worksheet " + worksheet.id}
                </option>
            );

        let groupTopics = this.state.availableWorksheets.groupTopics.map((topic, i) =>
            <option value={i} key={i}>
                {topic.name}
            </option>
        );

        let groupTopicWorksheets = this.state.groupTopicWorksheets === null ? "" :
            this.state.groupTopicWorksheets.map((worksheet, i) =>
                <option value={i} key={i}>
                    {"Worksheet " + worksheet.id}
                </option>
            );


        let tabs = <Tabs defaultActiveKey="singleTopic" id="worksheet-selector-tab">
            <Tab eventKey="singleTopic" title="Single Topics">
                <select className="custom-select" id="singleTopics" value={this.state.selectedSingleTopic} onChange={this.handleSingleTopicChange}>
                    <option value="Choose one..">Choose one..</option>
                    {singleTopics}
                </select>
                <div>
                    <b>Already created worksheets for the selected topic:</b>
                    <select className="custom-select" id="singleTopicWorksheets" value={this.state.selectedSingleTopicWorksheet} onChange={this.handleSingleTopicWorksheetChange}>
                        <option value="Choose one..">Choose one..</option>
                        {singleTopicWorksheets}
                    </select>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="singleTopicFavoriteId" value={this.state.includeSingleTopicFavoriteQs} onChange={this.handleSingleTopicFavoriteQsChange} />
                    <label className="form-check-label" htmlFor="singleTopicFavoriteId" >
                        Include Favorite Questions
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="singleDailyPracticeId" value={this.state.includeSingleTopicDailyPracticeQs} onChange={this.handleSingleTopicDailyPracticeQsChange} />
                    <label className="form-check-label" htmlFor="singleDailyPracticeId" >
                        Include Daily Practice Questions
                    </label>
                </div>
                <div>
                    <label><b>Enter no of question in each worksheet:</b></label>
                    <div className="form-group">
                        <input type="text" value={this.state.NoOfQuestionsInEachWorksheet} onChange={this.handleNoOfQuestionsInEachWorksheet} />
                    </div>
                </div>
                <div>
                    <label><b>Enter time to complete the worksheet (in Mins):</b></label>
                    <div className="form-group">
                        <input type="text" value={this.state.TimeToCompleteWorksheetInMinutes} onChange={this.handleTimeToCompleteWorksheet} />
                    </div>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary btn-wrap-text" onClick={this.handleSingleTopicCreateWorksheets} >Create Worksheets</button>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary btn-wrap-text" onClick={this.handleSingleTopicDeleteWorksheets} >Delete Topic Worksheets</button>
                </div>
            </Tab>
            <Tab eventKey="groupTopic" title="Group Topics">
                <select className="custom-select" id="groupTopics" value={this.state.selectedGroupTopic} onChange={this.handleGroupTopicChange}>
                    <option value="Choose one..">Choose one..</option>
                    {groupTopics}
                </select>
                <div>
                    <b>Already created worksheets for the selected topic:</b>
                    <select className="custom-select" id="groupTopicWorksheets" value={this.state.selectedGroupTopicWorksheet} onChange={this.handleGroupTopicWorksheetChange}>
                        <option value="Choose one..">Choose one..</option>
                        {groupTopicWorksheets}
                    </select>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="groupTopicFavoriteId" value={this.state.includeGroupTopicFavoriteQs} onChange={this.handleGroupTopicFavoriteQsChange} />
                    <label className="form-check-label" htmlFor="groupTopicFavoriteId" >
                        Include Favorite Questions
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="groupDailyPracticeId" value={this.state.includeGroupTopicDailyPracticeQs} onChange={this.handleGroupTopicDailyPracticeQsChange} />
                    <label className="form-check-label" htmlFor="groupDailyPracticeId" >
                        Include Daily Practice Questions
                    </label>
                </div>
                <div>
                    <label><b>Enter no of question in each worksheet:</b></label>
                    <div className="form-group">
                        <input type="text" value={this.state.NoOfQuestionsInEachWorksheet} onChange={this.handleNoOfQuestionsInEachWorksheet} />
                    </div>
                </div>
                <div>
                    <label><b>Enter time to complete the worksheet (in Mins):</b></label>
                    <div className="form-group">
                        <input type="text" value={this.state.TimeToCompleteWorksheetInMinutes} onChange={this.handleTimeToCompleteWorksheet} />
                    </div>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary btn-wrap-text" onClick={this.handleGroupTopicCreateWorksheets} >Create Worksheets</button>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary btn-wrap-text" onClick={this.handleDeleteGroupTopic} >Delete Worksheets</button>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary btn-wrap-text" onClick={this.handleSyncQuestions} >Sync Questions</button>
                </div>
            </Tab>
            <Tab eventKey="createGroupTopic" title="Create Group Topic">
                <CreateGroupTopic singleTopics={this.state.availableWorksheets.singleTopics} onGroupCreated={this.handleGroupCreated} />
            </Tab>
        </Tabs>;

        return (<div>
            {tabs}
        </div>);
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderAvailableWorksheets(this.state);

        return (
            <form>
                {contents}
            </form>
        );
    }
}
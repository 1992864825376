import React, { Component } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

class CreateGroupTopic extends Component {
    static displayName = CreateGroupTopic.name;

    constructor(props) {
        super(props);
        this.state = {
            singleTopics: this.props.singleTopics,
            currentSelectedTopicIndex: -1,
            selectedTopics: [],
            selectedTopicToRemove: null,
            groupTopicName: "",
            submitStatus: null,
        };

        this.handleSelectInSelectedTopics = this.handleSelectInSelectedTopics.bind(this);
        this.handleAddTopic = this.handleAddTopic.bind(this);
        this.handleRemoveTopic = this.handleRemoveTopic.bind(this);
        this.handleTopicChange = this.handleTopicChange.bind(this);
        this.handleCreateGroup = this.handleCreateGroup.bind(this);
        this.handleGroupTopicName = this.handleGroupTopicName.bind(this);
    }

    handleGroupTopicName(e) {
        this.setState({ groupTopicName: e.target.value });
        e.preventDefault();
    }

    handleTopicChange(e) {
        let selectedIndex = e.target.selectedIndex;
        if (selectedIndex > 0) {
            this.setState({ currentSelectedTopicIndex: selectedIndex - 1 });
        }

        e.preventDefault();
    }

    handleCreateGroup(e) {
        let topics = "";
        for (let i = 0; i < this.state.selectedTopics.length; i++) {
            if (topics === "") {
                topics = this.state.selectedTopics[i];
            } else {
                topics = topics + "," + this.state.selectedTopics[i];
            }
        }

        let postData = {
            name: this.state.groupTopicName,
            topics: topics
        };

        fetch('api/worksheet/CreateGroupTopic',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            })
            .then(response => {
                this.setState({ submitStatus: "Group topic is created successfully." });
                this.props.onGroupCreated();
            });
        e.preventDefault();
    }

    handleAddTopic(e) {
        if (this.state.currentSelectedTopicIndex >= 0) {
            let selectedTopic = this.state.singleTopics[this.state.currentSelectedTopicIndex];

            let selTopics = this.state.selectedTopics;
            if (selTopics.indexOf(selectedTopic) === -1) {
                selTopics.push(selectedTopic);


                // Set the suggested name
                let grpTopicName = "";
                for (let i = 0; i < selTopics.length; i++) {
                    let topic = selTopics[i];
                    if (grpTopicName === "") {
                        grpTopicName = topic.substring(topic.lastIndexOf("/") + 1).replace(/\s/g, "");
                    } else {
                        grpTopicName = grpTopicName +
                            "-" +
                            topic.substring(topic.lastIndexOf("/") + 1).replace(/\s/g, "");
                    }
                }

                this.setState({ selectedTopics: selTopics, groupTopicName: grpTopicName });
            }
        }

        e.preventDefault();
    }

    handleSelectInSelectedTopics(e) {
        let topic = e.target.innerText;
        if (topic !== undefined && topic !== null && topic !== "") {
            let selTopics = this.state.selectedTopics;
            let index = selTopics.indexOf(topic);
            if (index !== -1) {
                this.setState({ selectedTopicToRemove: topic });
            }
        }

        e.preventDefault();
    }

    handleRemoveTopic(e) {
        let topic = this.state.selectedTopicToRemove;
        if (topic !== undefined && topic !== null && topic !== "") {
            let selTopics = this.state.selectedTopics;
            let index = selTopics.indexOf(topic);
            if (index !== -1) {
                selTopics.splice(index, 1);
                this.setState({ selectedTopics: selTopics });
            }
        }

        e.preventDefault();
    }

    render() {
        let singleTopics = this.state.singleTopics.map((topic, i) =>
            <option value={i} key={i}>
                {topic}
            </option>
        );

        let selectedTopics = this.state.selectedTopics.map((topic, i) =>
            <ListGroup.Item action key={i} onClick={this.handleSelectInSelectedTopics} > {topic}</ListGroup.Item>
        );

        return (
            <div>
                <div>
                    <div>
                        {this.state.submitStatus}
                    </div>
                    <label><b>Select topic for group creation:</b></label>
                    <select className="custom-select" id="groupTopics" value={this.state.currentSelectedTopicIndex} onChange={this.handleTopicChange}>
                        <option value="Choose one..">Choose one..</option>
                        {singleTopics}
                    </select>
                    <div className="form-group">
                        <button className="btn btn-primary btn-wrap-text" onClick={this.handleAddTopic} >Add topic</button>
                    </div>
                </div>
                <div>
                    <label><b>List of topics selected for group:</b></label>
                    <ListGroup>
                        {selectedTopics}
                    </ListGroup>
                    <div className="form-group">
                        <button className="btn btn-primary btn-wrap-text" onClick={this.handleRemoveTopic} >Remove topic</button>
                    </div>
                </div>
                <div>
                    <label><b>Enter group topic name:</b></label>
                    <div className="form-group">
                        <input type="text" value={this.state.groupTopicName} onChange={this.handleGroupTopicName} />
                    </div>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary btn-wrap-text" onClick={this.handleCreateGroup} >Create Group</button>
                </div>
            </div>
        );
    }
}

export default CreateGroupTopic;
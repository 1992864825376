import React, { Component } from 'react';
import { BootstrapTable, SizePerPageDropDown, TableHeaderColumn } from 'react-bootstrap-table';

export class CompletedQuizList extends Component {
    static displayName = CompletedQuizList.name;

    constructor(props) {
        super(props);
        this.state = { completedExamListModel: null, loading: true };

        this.renderSizePerPageDropDown = this.renderSizePerPageDropDown.bind(this);
        this.onToggleDropDown = this.onToggleDropDown.bind(this);

        fetch('api/Exam/GetCompletedExamList')
            .then(response => response.json())
            .then(data => {
                this.setState({ completedExamListModel: data, loading: false });
            });
    }

    onToggleDropDown(e, toggleDropDown) {
        // do your stuff here
        console.log('toggle dropdown');
        toggleDropDown();
        e.preventDefault();
    }

    renderSizePerPageDropDown(props) {
        return (
            <SizePerPageDropDown
                className='my-size-per-page'
                btnContextual='btn-warning'
                variation='dropup'
                onClick={(e) => this.onToggleDropDown(e, props.toggleDropDown)} />
        );
    }

    reviewCellFormatter(cell, row) {
        return (<div><a target="_blank" href={cell}>Review</a></div>);
    }

    reportCellFormatter(cell, row) {
        return (<div><a target="_blank" href={cell}>Report</a></div>);
    }

    retakeCellFormatter(cell, row) {
        return (<div><a target="_blank" href={cell}>Retake Exam</a></div>);
    }

    renderTable() {
        const options = {
            sizePerPageDropDown: this.renderSizePerPageDropDown
        };
        return (<div>
            <BootstrapTable
                data={this.state.completedExamListModel.completedExams}
                options={options}
                pagination>
                <TableHeaderColumn dataField='examId' isKey={true}>Id</TableHeaderColumn>
                <TableHeaderColumn dataField='worksheetName'>Worksheet Name</TableHeaderColumn>
                <TableHeaderColumn dataField='topicName'>Single/Group Topic Name</TableHeaderColumn>
                <TableHeaderColumn dataField='createdOn'>Created on</TableHeaderColumn>
                <TableHeaderColumn dataField='reviewed'>Reviewed</TableHeaderColumn>
                <TableHeaderColumn dataField='reviewUrl' dataFormat={this.reviewCellFormatter}>Review</TableHeaderColumn>
                <TableHeaderColumn dataField='reportUrl' dataFormat={this.reportCellFormatter}>Report</TableHeaderColumn>
                <TableHeaderColumn dataField='retakeUrl' dataFormat={this.retakeCellFormatter}>Retake Exam</TableHeaderColumn>
            </BootstrapTable>
        </div>);
    }

    render() {

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderTable();

        return (
            <form>
                {contents}
            </form>
        );
    }
}